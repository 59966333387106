<template>
  <div class="form-row">
    <slot />
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.form-row {
  position: relative;
  display: grid;
  width: 100%;
  max-width: 800px;
}
</style>
